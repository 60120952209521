import React from "react";
import {H2_COLORS, H3_COLORS} from "../constants";
import {Button, Form} from "react-bootstrap";

const ApplicationForm = () => {
    return (
        <Form className="formCalculator" id='applicationForm'>
            <h2 style={H2_COLORS}>Заполните форму заявки</h2>
            <h4 style={H3_COLORS}>ПОДАЙТЕ ЗАЯВКУ СЕЙЧАС
                И ПОЛУЧИТЕ БЕСПЛАТНУЮ
                ПРОВЕРКУ КРЕДИТНОЙ
                ИСТОРИИ И КОНСУЛЬТАЦИЮ</h4>

            <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Ваше имя</Form.Label>
                <Form.Control type="text" placeholder="Введите имя" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPhone">
                <Form.Label>Ваш номер телефона</Form.Label>
                <Form.Control type="text" placeholder="+7 (___) ___-__-__" />
            </Form.Group>

            <Button variant="primary" type="submit">
                Отправить заявку
            </Button>
        </Form>
    )
}

export default ApplicationForm;