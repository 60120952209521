import React, { Fragment } from "react";
import {CARD_IMG, CARD_INFO, H2_COLORS, H3_COLORS} from '../constants';
import { Row, Col, Card } from "react-bootstrap";
import CardFon from '../images/cardFonIsh.jpg';

const Home = () => {
    return (
        <Fragment>
            <h2 style={H2_COLORS}>98% ОДОБРЕНИЯ</h2>
            <h4 style={H3_COLORS}>РАБОТАЕМ БЕЗ ПРЕДОПЛАТЫ</h4>
            <h4>ПОМОЖЕМ С ОДОБРЕНИЕМ ИПОТЕКИ</h4>

            <div className='homeBlock'>
                <Row xs={1} md={5} className="g-4">
                    {Array.from({ length: 10 }).map((_, idx) => (
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={CardFon} />
                                <Card.Title style={CARD_IMG}>{CARD_INFO[idx].title}</Card.Title>
                                <Card.Body>
                                    <Card.Text style={H3_COLORS}>
                                        {CARD_INFO[idx].description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </Fragment>
    )
}

export default Home