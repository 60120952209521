import React, { useState } from "react";
import { Offcanvas, Container, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";


const Sidebar = () => {
    const [show, setShow] = useState(false);
    const closeSidebar = () => setShow(false);
    const showSidebar = () => setShow(true);
    return (
        <>
            <Container className='p-4'>
                <Button variant="primary" onClick={showSidebar}>
                    Launch Sidebar
                </Button>
                <Offcanvas show={show} onHide={closeSidebar}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Ипотека</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Link to='/admin'>Профиль</Link>
                        <Link to='/admin/slider'>Слайдер</Link>
                    </Offcanvas.Body>
                </Offcanvas>
            </Container>
        </>
    );
}

export default Sidebar;