import React, { useState } from 'react';
import {Button, Table} from "react-bootstrap";

const EditableTable = (params) => {
    const [data, setData] = useState(params);

    const handleAddRow = () => {
        setData([...data, { id: data.length + 1, name: '', age: 0 }]);
    };

    const handleDeleteRow = (id) => {
        setData(data.filter((item) => item.id !== id));
    };

    const handleEditField = (id, field, value) => {
        setData(data.map((item) => (item.id === id ? { ...item, [field]: value } : item)));
    };

    const handleSaveRow = (id) => {

    };

    return (
        <div>
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length && (data.map((row) => (
                        <tr key={row.id}>
                            <td>
                                <input
                                    type="text"
                                    value={row.name}
                                    onChange={(e) => handleEditField(row.id, 'name', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={row.age}
                                    onChange={(e) => handleEditField(row.id, 'age', parseInt(e.target.value))}
                                />
                            </td>
                            <td>
                                <Button onClick={() => handleDeleteRow(row.id)}>Delete</Button>
                                <Button onClick={() => handleSaveRow(row.id)}>Save</Button>
                            </td>
                        </tr>
                    )))}
                </tbody>
            </Table>
            <Button onClick={handleAddRow}>Add Row</Button>
        </div>
    );
};

export default EditableTable;