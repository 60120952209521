import React from "react";
import { H2_COLORS, H3_COLORS } from "../constants";
import { Card, Col, Row } from "react-bootstrap";
import Team1 from "../images/team/team1.jpg";

const Team = () => {
    return (
        <div className='team' id='teams'>
            <h2>НАША КОМАНДА</h2>

            <Row xs={1} md={5} className="g-4">
                {Array.from({ length: 5 }).map((_, idx) => (
                    <Col>
                        <Card>
                            <Card.Img variant="top" src={Team1} />
                            <Card.Body>
                                <Card.Title style={H2_COLORS}>Card title</Card.Title>
                                <Card.Text style={H3_COLORS}>
                                    This is a longer card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit longer.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default Team;