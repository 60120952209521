import React from "react";
import '../styles/App.css';
import { Carousel } from "react-bootstrap";
import Slide1 from '../images/slide1.jpg';
import Slide2 from '../images/slide2.jpg';
import Slide3 from '../images/slide3.jpg';

import Item1 from '../images/icons/header-bottom-item-img-1.png';
import Item2 from '../images/icons/header-bottom-item-img-2.png';
import Item3 from '../images/icons/header-bottom-item-img-3.png';
import Item4 from '../images/icons/header-bottom-item-img-4-2.png';

const Header = () => {
    return (
        <header className="App-header">
            <Carousel fade>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Slide1}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <div className='mask'>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Slide2}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <div className='mask'>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Slide3}
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <div className='mask'>
                            <h3>Third slide label</h3>
                            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <div className="header-bottom-wrap">
                <div className="container">
                    <div className="header-bottom">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="col-12 col-lg-3">
                                <div className="h-100 header-bottom-item d-flex align-items-center br-2">
                                    <div className="header-bottom-item-img me-3">
                                        <img src={Item1} alt="" />
                                    </div>
                                    <div className="header-bottom-item-title">Без первоначального взноса</div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <div className="h-100 header-bottom-item d-flex align-items-center br-2">
                                    <div className="header-bottom-item-img me-3">
                                        <img src={Item2} alt="" />
                                    </div>
                                    <div className="header-bottom-item-title">Без подтверждения доходов</div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <div className="h-100 header-bottom-item d-flex align-items-center br-2">
                                    <div className="header-bottom-item-img me-3">
                                        <img src={Item3} alt="" />
                                    </div>
                                    <div className="header-bottom-item-title">Одобрение любой сложности</div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <div className="h-100 header-bottom-item d-flex align-items-center">
                                    <div className="header-bottom-item-img me-3">
                                        <img src={Item4} alt="" />
                                    </div>
                                    <div className="header-bottom-item-title">Работаем по всей России</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header