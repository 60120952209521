import React, {Fragment} from "react";
import Sidebar from "./Sidebar";
import EditableTable from "./Table";

const Users = () => {
    return (
        <div className='users'>
            <Sidebar />
            <div className='tableBlock'>
                <h1>Настройка профиля</h1>
                <EditableTable />
            </div>
        </div>
    )
}

export default Users;