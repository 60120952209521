import React from "react";

const Advantages = () => {
    return (
        <div className='advantages' id='advantages'>
            <h2>ФИКСИРОВАННЫЕ СТАВКИ</h2>
            <h2>НА ВЕСЬ СРОК ОТ 5% ГОДОВЫХ В РУБЛЯХ</h2>
            <h2>ПЕРВОНАЧАЛЬНЫЙ ВЗНОС ОТ 0%</h2>
        </div>
    )
}

export default Advantages;