import React from "react";
import '../styles/App.css';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Logo from '../images/logo.png';
import Vk from '../images/socialIcons/vk.png';
import Tg from '../images/socialIcons/telegram.png';
import Ws from '../images/socialIcons/whatsapp.png';


const NavBarMenu = () => {
    return (
        <Navbar style={{backgroundColor: '#27497b'}} variant="dark" expand="lg">
            <Container>
                <Navbar.Brand href="#"><img alt='' className='logo' src={Logo} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link className='nav-link' href="#">Что решаем</Nav.Link>
                        <Nav.Link className='nav-link' href="#advantages">Преимущества</Nav.Link>
                        <Nav.Link className='nav-link' href="#mortgageCalculator">Ипотечный калькулятор</Nav.Link>
                        <Nav.Link className='nav-link' href="#problems">Мы поможем решить вашу проблему</Nav.Link>

                        <Nav.Link className='nav-link' alt='' href="#"><img src={Vk}/></Nav.Link>
                        <Nav.Link className='nav-link' alt='' href="#"><img src={Ws}/></Nav.Link>
                        <Nav.Link className='nav-link' alt='' href="#"><img src={Tg}/></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBarMenu;