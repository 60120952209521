import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Slider from "./admin/Slider";
import Users from "./admin/Users";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route exact path="/" element={<App />} />
              <Route path="/admin" element={<Users />} />
              <Route path="/admin/slider" element={<Slider />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);
