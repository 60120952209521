import React from "react";
import {Nav} from "react-bootstrap";
import Vk from "../images/socialIcons/vk.png";
import Ws from "../images/socialIcons/whatsapp.png";
import Tg from "../images/socialIcons/telegram.png";

const Footer = () => {
    return (
        <footer>
            <div className='linksFooter'>
                <div className='linksNav'>
                    <ul>
                        <li>
                            <a href='#applicationForm'>Оставить заявку</a>
                        </li>
                        <li>
                            <a href='#questions'>Часто задаваемые вопросы</a>
                        </li>
                        <li>
                            <a href='#part'>Наши партнеры</a>
                        </li>
                        <li>
                            <a href='#teams'>Команда</a>
                        </li>
                    </ul>
                </div>
                <div className='legalLinks'>
                    <ul>
                        <li>
                            <a href=''>Соглашение на обработку персональных данных</a>
                        </li>
                    </ul>
                </div>
                <div className='contacts'>
                    <ul>
                        <li>
                            <a href=''>Тел: 8-900-000-00-00</a>
                        </li>
                        <li>
                            <a href=''>Mail: shangra07@mail.ru</a>
                        </li>
                        <li className='socialLinks'>
                            <Nav.Link className='nav-link' alt='' href="#"><img className='social' src={Vk}/></Nav.Link>
                            <Nav.Link className='nav-link' alt='' href="#"><img className='social' src={Ws}/></Nav.Link>
                            <Nav.Link className='nav-link' alt='' href="#"><img className='social' src={Tg}/></Nav.Link>
                        </li>
                    </ul>
                    {/*<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A5d1d85e334e59c2577cedd739e0816e68af66027618e6d6ffe935ee81c519d57&amp;source=constructor" width="100%" height="100%" frameBorder="0"></iframe>*/}
                </div>
            </div>

            <div className='copyrite'>
                <span>© Bergcom, 2024</span>
            </div>
        </footer>
    )
}

export default Footer;