import React from "react";
import { H2_COLORS, H3_COLORS } from "../constants";
import { Button, Form } from "react-bootstrap";

const Problems = () => {
    return (
        <Form className="formCalculator" id='problems'>
            <h2 style={H2_COLORS}>Узнайте стоимость решения вашего вопроса</h2>
            <h4 style={H3_COLORS}>ПОДАЙТЕ ЗАЯВКУ СЕЙЧАС
                И ПОЛУЧИТЕ БЕСПЛАТНУЮ
                ПРОВЕРКУ КРЕДИТНОЙ
                ИСТОРИИ И КОНСУЛЬТАЦИЮ</h4>

            <Form.Select aria-label="Default select example">
                <Form.Label>Причина обращения</Form.Label>
                <option>Плохая кредитная история</option>
                <option value="1">Высокая кредитная нагрузка</option>
                <option value="2">Отказывают банки</option>
                <option value="3">Сделка между родственниками</option>
                <option value="4">Покупка коммерческой недвижимости</option>
                <option value="5">Увеличение кредитного лимита</option>
                <option value="6">Кредит под залог недвижимости</option>
                <option value="7">Без подтверждения доходов</option>
            </Form.Select>

            <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Ваше имя</Form.Label>
                <Form.Control type="text" placeholder="Введите имя" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupPhone">
                <Form.Label>Ваш номер телефона</Form.Label>
                <Form.Control type="text" placeholder="+7 (___) ___-__-__" />
            </Form.Group>

            <Button variant="primary" type="submit">
                Отправить заявку
            </Button>
        </Form>
    )
}

export default Problems;