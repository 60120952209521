import React, {useState, useEffect, Fragment} from "react";
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useLocation } from "react-router-dom";

import Home from "./components/Home";
import NavBarMenu from './components/NavBarMenu';
import Header from "./components/Header";
import Advantages from "./components/Advantages";
import ApplicationForm from "./components/ ApplicationForm";
import Team from "./components/Team";
import Questions from "./components/Questions";
import MortgageCalculator from "./components/MortgageCalculator";
import Partners from "./components/Partners";
import Problems from "./components/Problems";
import Footer from "./components/Footer";


function App() {
    const location = useLocation();

    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    useEffect(() => {
        if (location !== displayLocation) setTransistionStage("fadeOut");
    }, [location, displayLocation]);


    return (
        <Fragment>
            <NavBarMenu />
            <Header />

            <div
                className={`App ${transitionStage}`}
                onAnimationEnd={() => {
                    if (transitionStage === "fadeOut") {
                        setTransistionStage("fadeIn");
                        setDisplayLocation(location);
                    }
                }}
            >
                <Home />
                <Advantages />
                <ApplicationForm />
                <Team />
                <Questions />
                <MortgageCalculator />
                <Partners />
                <Problems />
            </div>
            <Footer />
        </Fragment>
    );
}

export default App;
