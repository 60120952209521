import React, { Fragment } from "react";
import { H2_COLORS } from "../constants";
import { Accordion } from "react-bootstrap";
import Approval from "./Approval";

const Questions = () => {
    return (
        <Fragment id='questions'>
            <Accordion defaultActiveKey="0">
                <h2 style={H2_COLORS}>Часто задаваемые вопросы</h2>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>У меня были просрочки по кредитам. Могу ли я получить ипотеку?</Accordion.Header>
                    <Accordion.Body>
                        - Информация в бюро кредитных историй может быть отображена некорректно, из-за чего клиенты могут сталкиваться с отказами. Если просрочки действительно были, мы подберем Банк, с более лояльными требованиями к кредитной истории. В обоих случаях мы доведем нашего Клиента до решения его вопроса
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Мне урезали сумму одобрения, могу ли я рассчитывать на одобрение всей суммы или нужно соглашаться с тем, что предлагает Банк?</Accordion.Header>
                    <Accordion.Body>
                        - Это довольно частая ситуация. При подаче заявки через нашу компанию , мы корректно рассчитаем вашу кредитную нагрузку и получим решение в Банке на всю необходимую вам сумму
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Банк отказал в ипотеке, причина неизвестна. Что делать?</Accordion.Header>
                    <Accordion.Body>
                        - Первым делом получить бесплатную консультацию у нас :) а далее мы выясним причину отказа и подберем банк в котором вы не только получите одобрение , но и получите выгодные условия на весь срок ипотеки.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>У меня есть действующие кредиты. Боюсь что банки откажут.</Accordion.Header>
                    <Accordion.Body>
                        - Мы каждый день работаем с клиентами у которых более 3, 5 и 10 кредитов. Грамотный расчет и выбор банка нашими экспертами, приводит наших клиентов к желаемому одобрению
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Зачем мне обращаться к вам, если я могу самостоятельно пойти в Банк?</Accordion.Header>
                    <Accordion.Body>
                        - Конечно вы можете пойти в Банк самостоятельно, разница в том , что через нас вы сэкономите не только время но и деньги, а также поучите гарантированное решение на выгодных именно для вас условиях.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Approval />
        </Fragment>
    )
}

export default Questions;