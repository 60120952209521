import React from "react";
import { H2_COLORS } from "../constants";
import { Figure, Col, Row } from "react-bootstrap";
import Part from "../images/partners/part.svg";

const Partners = () => {
    return (
        <div className='partners' id='part'>
            <h2 style={H2_COLORS}>НАШИ ПАРТНЕРЫ</h2>

            <Row xs={1} md={5} className="g-4">
                {Array.from({ length: 5 }).map((_, idx) => (
                    <Col>
                        <Figure>
                            <Figure.Image
                                width={171}
                                height={180}
                                alt="171x180"
                                src={Part}
                            />
                        </Figure>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default Partners;