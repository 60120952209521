import React, {useState, useEffect} from "react";
import Sidebar from "./Sidebar";
import EditableTable from "./Table";
import axios from 'axios';


const Slider = () => {
    const [postData, setPostData] = useState([]);

    useEffect(() => {
        const loc = window.location.origin;
        console.log(loc)
        const fetchData = async () => {
            try {
                const response = await axios.get(`./server/slider.json`);
                setPostData(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='users'>
            <Sidebar />
            <div className='tableBlock'>
                <h1>Слайдер</h1>
                <EditableTable data={postData} />
            </div>
        </div>
    )
}

export default Slider;