import React from "react";
import { Button } from "react-bootstrap";

const Approval = () => {
    return (
        <div className='advantages'>
            <h2>Мы гарантировно поможем Вам получить ипотечный кредит!</h2>
            <h4>Просто оставьте заявку на нашем сайте, и опытные специалисты свяжутся с Вами в кратчайшие сроки.
                Мы знаем все тонкости ипотечного кредитования и поможем Вам в каждом шаге этого важного процесса.
                Доверьтесь профессионалам и получите желаемую ипотеку без лишних хлопот!</h4>

            <Button variant="light" style={{width: '400px'}} type="submit">
                Отправить заявку
            </Button>
        </div>
    )
}

export default Approval;