import React, { useState } from "react";
import { Form, Row, Button, Col } from "react-bootstrap";

const MortgageCalculator = () => {

    const [loanAmount, setLoanAmount] = useState(100000);
    const [interestRate, setInterestRate] = useState(5);
    const [loanTerm, setLoanTerm] = useState(30);

    const handleLoanAmountChange = (e) => {
        setLoanAmount(e.target.value);
    };

    const handleInterestRateChange = (e) => {
        setInterestRate(e.target.value);
    };

    const handleLoanTermChange = (e) => {
        setLoanTerm(e.target.value);
    };

    const calculateMonthlyPayment = () => {
        const monthlyRate = interestRate / 100 / 12;
        const months = loanTerm * 12;
        const monthlyPayment = (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -months));
        return monthlyPayment.toFixed(2);
    };

    return (
        <Form className="formCalculator" id='mortgageCalculator'>
            <h2>Ипотечный калькулятор</h2>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Сумма кредита: ₽{loanAmount}</Form.Label>
                    <Form.Range min="10000" max="1000000" step="1000" value={loanAmount} onChange={handleLoanAmountChange} />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Процентная ставка: {interestRate}%</Form.Label>
                    <Form.Range min="1" max="20" step="0.1" value={interestRate} onChange={handleInterestRateChange} />
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Срок кредита: {loanTerm} лет</Form.Label>
                <Form.Range min="5" max="40" step="1" value={loanTerm} onChange={handleLoanTermChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress2">
                <Form.Label>Ежемесячный платеж: ₽{calculateMonthlyPayment()}</Form.Label>
            </Form.Group>

            <Button variant="primary" type="submit">
                Отправить заявку
            </Button>
        </Form>
    );
}

export default MortgageCalculator;